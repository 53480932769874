// You can customize the template with the help of this file

const hostname = window.location.hostname;
const themeConfig = {
  app: {
    appName: '',
    appLogoImage: '',
  },
  layout: {
    isRTL: false,
    skin: 'light', // light, dark, bordered, semi-dark
    type: 'vertical', // vertical, horizontal
    contentWidth: 'boxed', // full, boxed
    menu: {
      isHidden: false,
      isCollapsed: false,
    },
    navbar: {
      type: 'floating', // static, sticky, floating, hidden
      backgroundColor: 'white', // default value
    },
    footer: {
      type: 'static', // static, sticky, hidden
      text: '', // default value
    },
    customizer: false,
    scrollTop: true, // Enable scroll to top button
    toastPosition: 'top-right', // top-left, top-center, top-right, bottom-left, bottom-center, bottom-right
  },
};

// Use a switch statement to set the properties based on the hostname
switch (hostname) {
  case 'aconcagua.app':
    themeConfig.app.appName = 'Aconcagua - Domain 1';
    themeConfig.app.appLogoImage = require('@src/assets/images/logo/logo.png');
    themeConfig.layout.navbar.backgroundColor = 'white';
    themeConfig.layout.footer.text = 'Domain 1 Footer Text';
    break;
  case 'aconcaguapsav.web.app':
    themeConfig.app.appName = 'Aconcagua - Domain 1';
    themeConfig.app.appLogoImage = require('@src/assets/images/logo/logo.png');
    themeConfig.layout.navbar.backgroundColor = 'white';
    themeConfig.layout.footer.text = 'Domain 1 Footer Text';
    break;
  case 'bishorn.xyz':
    themeConfig.app.appName = 'Aconcagua - Domain 2';
    themeConfig.app.appLogoImage = require('@src/assets/images/logo/logo_bishorn.png');
    themeConfig.layout.navbar.backgroundColor = 'white';
    themeConfig.layout.footer.text = 'Domain 2 Footer Text';
    break;
  case 'bishorn.web.app':
    themeConfig.app.appName = 'Aconcagua - Domain 2';
    themeConfig.app.appLogoImage = require('@src/assets/images/logo/logo_bishorn.png');
    themeConfig.layout.navbar.backgroundColor = 'white';
    themeConfig.layout.footer.text = 'Domain 2 Footer Text';
    break;
  case 'catedral-fb.web.app':
    themeConfig.app.appName = 'Aconcagua - Domain 1';
    themeConfig.app.appLogoImage = require('@src/assets/images/logo/logo.png');
    themeConfig.layout.navbar.backgroundColor = 'white';
    themeConfig.layout.footer.text = 'Domain 1 Footer Text';
    break;
  case 'catedral-bishorn.web.app':
    themeConfig.app.appName = 'Aconcagua - Domain 2';
    themeConfig.app.appLogoImage = require('@src/assets/images/logo/logo_bishorn.png');
    themeConfig.layout.navbar.backgroundColor = 'white';
    themeConfig.layout.footer.text = 'Domain 2 Footer Text';
    break;
  case 'use-lanin.web.app':
    themeConfig.app.appName = 'Aconcagua - Domain 1';
    themeConfig.app.appLogoImage = require('@src/assets/images/logo/logo.png');
    themeConfig.layout.navbar.backgroundColor = 'white';
    themeConfig.layout.footer.text = 'Domain 1 Footer Text';
    break;
  case 'lanin-bishorn.web.app':
    themeConfig.app.appName = 'Aconcagua - Domain 2';
    themeConfig.app.appLogoImage = require('@src/assets/images/logo/logo_bishorn.png');
    themeConfig.layout.navbar.backgroundColor = 'white';
    themeConfig.layout.footer.text = 'Domain 2 Footer Text';
    break;
  default:
    themeConfig.app.appName = 'Localhost';
    themeConfig.app.appLogoImage = require('@src/assets/images/logo/logo.png');
    themeConfig.layout.navbar.backgroundColor = 'white';
    themeConfig.layout.footer.text = 'Default Footer Text';
    break;
}

export default themeConfig;
