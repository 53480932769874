/* eslint-disable no-console */
// import { Config } from 'vs-core';

// export const ENV = Config.getEnvConfig('REACT_APP_ENV');
export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;

export const FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY;
export const FIREBASE_AUTH_DOMAIN = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN;
export const FIREBASE_PROJECT_ID = process.env.REACT_APP_FIREBASE_PROJECT_ID;
export const FIREBASE_STORAGE_BUCKET = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET;
export const FIREBASE_MESSAGING_SENDER_ID = process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID;
export const FIREBASE_MEASURAMENT_ID = process.env.REACT_APP_FIREBASE_MEASURAMENT_ID;
export const FIREBASE_APP_ID = process.env.REACT_APP_FIREBASE_APP_ID;

export const API_URL = process.env.REACT_APP_API_URL;
export const API_URL_LEADS = process.env.REACT_APP_API_URL_LEADS;
export const API_URL_USERS = process.env.REACT_APP_API_URL_USERS;
export const API_URL_PRODUCTS = process.env.REACT_APP_API_URL_PRODUCTS;
export const API_URL_STAFF = process.env.REACT_APP_API_URL_STAFF;
export const API_URL_TASKS = process.env.REACT_APP_API_URL_TASKS;
export const API_URL_ASPECTS = process.env.REACT_APP_API_URL_ASPECTS;
export const API_URL_LEVELS = process.env.REACT_APP_API_URL_LEVELS;
export const API_URL_USER_TASKS = process.env.REACT_APP_API_URL_USER_TASKS;
export const API_URL_USER_TOUCHPOINTS = process.env.REACT_APP_API_URL_USER_TOUCHPOINTS;
export const API_URL_ATTACHMENTS = process.env.REACT_APP_API_URL_ATTACHMENTS;
export const API_URL_PROGRESS_OPTIONS = process.env.REACT_APP_API_URL_PROGRESS_OPTIONS;
export const API_URL_HOOKED_EVENTS = process.env.REACT_APP_API_URL_HOOKED_EVENTS;
export const API_URL_INSIGHTS = process.env.REACT_APP_API_URL_INSIGHTS;
export const API_URL_USER_DYNAMIC_ATTRIBUTES =
  process.env.REACT_APP_API_URL_USER_DYNAMIC_ATTRIBUTES;
export const API_URL_USER_ATTRIBUTES_TYPES = process.env.REACT_APP_API_URL_USER_ATTRIBUTES_TYPES;
export const API_URL_USER_PRODUCTS = process.env.REACT_APP_API_URL_USER_PRODUCTS;
export const API_URL_USER_WELL_BEING_ATTIBUTES =
  process.env.REACT_APP_API_URL_USER_WELL_BEING_ATTIBUTES;
export const API_URL_USER_CALENDARS = process.env.REACT_APP_API_URL_USER_CALENDARS;
export const API_URL_GOOGLE_OAUTH = process.env.REACT_APP_API_URL_GOOGLE_OAUTH;
export const API_URL_COMPANIES = process.env.REACT_APP_API_URL_COMPANIES;
export const API_URL_COMPANY_EMPLOYEES = process.env.REACT_APP_API_URL_COMPANY_EMPLOYEES;
export const API_URL_COMPANY_SURVEY_QUESTIONS =
  process.env.REACT_APP_API_URL_COMPANY_SURVEY_QUESTIONS;
export const API_URL_COMPANY_SURVEYS = process.env.REACT_APP_API_URL_COMPANY_SURVEYS;
export const API_URL_COMPANY_CLIENTS = process.env.REACT_APP_API_URL_COMPANY_CLIENTS;
export const API_URL_REACT_APP_BLOCKCHAIN_NETWORK = process.env.REACT_APP_BLOCKCHAIN_NETWORK;

export const API_URL_TRANSACTION_REQUESTS = process.env.REACT_APP_API_URL_TRANSACTION_REQUESTS;

export const WHATSAPP_PHONE_NUMBER = '5491134120450';

export const API_URL_VAULTS = process.env.REACT_APP_API_URL_VAULTS;
export const API_URL_VAULT_INSTALLMENTS = process.env.REACT_APP_API_URL_VAULT_INSTALLMENTS;

export const API_URL_VAULT_TRANSACTIONS = process.env.REACT_APP_API_URL_VAULT_TRANSACTIONS;
export const API_URL_REMINDERS = process.env.REACT_APP_API_URL_REMINDERS;

export const API_URL_MARKET_CAP = process.env.REACT_APP_API_URL_MARKET_CAP;
export const API_URL_MARKET_CAP_USDARS = process.env.REACT_APP_API_URL_MARKET_CAP_USDARS;

export const ACONCAGUA_COMPANY_ID = process.env.REACT_APP_ACONCAGUA_COMPANY_ID;
