/**
=========================================================
* Aconcagua React - v3.0.0
=========================================================

* Product Page: https://www.aconcagua.apo/product/soft-ui-dashboard-pro-react


Coded by www.aconcagua.apo

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

export default {
  defaultProps: {
    underline: 'none',
    color: 'inherit',
  },
};
