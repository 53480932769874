// ** React Imports
import { Suspense, lazy } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

// ** Redux Imports
import { store } from './redux/store';
import { Provider } from 'react-redux';

// ** Intl, CASL & ThemeColors Context
import ability from './configs/acl/ability';
import { AbilityContext } from './utility/context/Can';
import { ThemeContext } from './utility/context/ThemeColors';

import { Web3ReactProvider } from '@web3-react/core';
import { getProvider } from 'utility/web3/provider';

// ** ThemeConfig
import themeConfig from './configs/themeConfig';

// ** Toast
import { Toaster } from 'react-hot-toast';

// ** i18n
import './configs/i18n';

// ** Spinner (Splash Screen)
import Spinner from '@components/spinner/Fallback-spinner';

// ** Ripple Button
import './@core/components/ripple-button';

// ** PrismJS
import 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';
import 'prismjs/components/prism-jsx.min';

// ** React Perfect Scrollbar
import 'react-perfect-scrollbar/dist/css/styles.css';

// ** React Hot Toast Styles
import '@styles/react/libs/react-hot-toasts/react-hot-toasts.scss';

// ** Core styles
// Domain based Text
import { getDomainText } from 'configs/domainText';

import './@core/assets/fonts/feather/iconfont.css';

// Conditional import of scss stylesheetes based on domain
const scssPathKey = getDomainText('scssPathKey');
if (scssPathKey === 'Aconcagua') {
  import('./@core/scss/core.scss');
} else {
  import('./@core/scss/core_bishorn.scss');
}

import './assets/scss/style.scss';

// ** Service Worker
import * as serviceWorker from './serviceWorker';

import { ENVIRONMENT } from '@configs/appConfig';

import { CookiesProvider } from 'react-cookie';
import { AuthProvider } from '@contexts/auth/auth.provider';

import { ThemeProvider } from '@mui/material/styles';

// Aconcagua React themes
import theme from 'assets/theme';

import { Buffer } from 'buffer/';

window.Buffer = window.Buffer || Buffer;

// ** Lazy load app
const LazyApp = lazy(() => import('./App'));

const container = document.getElementById('root');
const root = createRoot(container);

// avoid local machine inspectors
if (ENVIRONMENT === 'local') {
  setInterval(() => {
    const iframes = document.querySelectorAll('iframe');
    for (let i = 0; i < iframes.length; i++) {
      const iframe = iframes[i];

      if (iframe && (!iframe.id || iframe.id === '') && !iframe.src.includes('typeform'))
        iframes[i].parentNode.removeChild(iframes[i]);
    }
  }, 3000);
}

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <Suspense fallback={<Spinner />}>
        <CookiesProvider>
          <AuthProvider>
            <Web3ReactProvider getLibrary={getProvider}>
              <AbilityContext.Provider value={ability}>
                <ThemeContext>
                  <ThemeProvider theme={theme}>
                    <LazyApp />
                    <Toaster
                      position={themeConfig.layout.toastPosition}
                      toastOptions={{ className: 'react-hot-toast' }}
                    />
                  </ThemeProvider>
                </ThemeContext>
              </AbilityContext.Provider>
            </Web3ReactProvider>
          </AuthProvider>
        </CookiesProvider>
      </Suspense>
    </Provider>
  </BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
