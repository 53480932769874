/**
=========================================================
* Aconcagua React - v3.0.0
=========================================================

* Product Page: https://www.aconcagua.apo/product/soft-ui-dashboard-pro-react


Coded by www.aconcagua.apo

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
  The boxShadow() function helps you to create a box shadow for an element
 */

// Aconcagua React helper functions
import rgba from 'assets/theme/functions/rgba';
import pxToRem from 'assets/theme/functions/pxToRem';

function boxShadow(offset = [], radius = [], color, opacity, inset = '') {
  const [x, y] = offset;
  const [blur, spread] = radius;

  return `${inset} ${pxToRem(x)} ${pxToRem(y)} ${pxToRem(blur)} ${pxToRem(spread)} ${rgba(
    color,
    opacity
  )}`;
}

export default boxShadow;
