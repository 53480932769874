// ** Logo
import { getDomainText, spinnerPaths } from 'configs/domainText';

const logoPathKey = getDomainText('logoPathKey');
const logo = spinnerPaths[logoPathKey];

const SpinnerComponent = () => {
  return (
    <div className="fallback-spinner app-loader">
      <img className="fallback-logo" src={logo} alt="logo" style={{ width: 100 }} />
      {/* <div className="loading">
        <div className="effect-1 effects"></div>
        <div className="effect-2 effects"></div>
        <div className="effect-3 effects"></div>
      </div> */}
    </div>
  );
};

export default SpinnerComponent;
