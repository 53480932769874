/**
=========================================================
* Aconcagua React - v3.0.0
=========================================================

* Product Page: https://www.aconcagua.apo/product/soft-ui-dashboard-pro-react


Coded by www.aconcagua.apo

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Aconcagua React helper functions
import pxToRem from 'assets/theme/functions/pxToRem';

export default {
  defaultProps: {
    baseClassName: 'material-icons-round',
    fontSize: 'inherit',
  },

  styleOverrides: {
    fontSizeInherit: {
      fontSize: 'inherit !important',
    },

    fontSizeSmall: {
      fontSize: `${pxToRem(20)} !important`,
    },

    fontSizeLarge: {
      fontSize: `${pxToRem(36)} !important`,
    },
  },
};
